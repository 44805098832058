<template>
  <div class="voucher-wrapper"  :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <div  v-if="!voucherSuccess" class="voucher-container">
      <div class="voucher-logo">
        <img src="@/assets/icons/voucherlogo.svg" alt="Noor Play Logo" />
      </div>
      <h1 class="voucher-tittle">{{ title }}</h1>
      <p>{{ subtitle }}</p>
      <div class="voucher-input-group">
        <label for="voucher-code">{{ label }}</label>
        <input id="voucher-code" v-model="voucherCode" :placeholder="inputPlaceholder" type="text"   @input="clearError"/>
        <!-- Error message displayed if errorMsg is not empty -->
        <p v-if="errorMsg" class="voucher-error">{{ errorMsg }}</p>
      </div>
      <button class="voucher-btn" @click="verifyVoucher">{{ buttonText }}</button>
    </div>
    
    <!-- <payment   :couponCode="couponCode" :paymentDetail="paymentDetail" :closePopup="() => closePopup()" v-else> </payment> -->
    <VoucherSuccess
      v-else
      :planName="planName"
      :planExpireDate="planExpireDate"
    />
  </div>
  </template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import VoucherSuccess from "@/components/Templates/VoucherSuccess.vue";
import payment from "@/components/Popups/Payment/Payment.vue";

export default {
  components: {
    VoucherSuccess,
    payment
  },
  data() {
    return {
      title: "Redeem your voucher",
      // subtitle: "Please enter your voucher code to start your subscription",
      label: "Please enter your voucher code to start your subscription",
      buttonText: "Verify",
      voucherCode: "",
      // inputPlaceholder: "Enter your code here...",
      errorMsg: "", // Error messages
      voucherSuccess: false, // Voucher success state
      planName: "Monthly Plan",
      planExpireDate: null,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
    ]),
    backgroundImage() {
      if (this.localDisplayLang === "eng") {
        return this.isMobileDevice 
          ? require("@/assets/icons/english-mobile-background.jpg") 
          : require('@/assets/icons/english-large-background.jpg');
      } else if (this.localDisplayLang === "ara") {
        return this.isMobileDevice 
          ? require('@/assets/icons/arabic-mobile-background.jpg') 
          : require('@/assets/icons/arabic-large-background.jpg');
      }
      return '';
    },
    isMobileDevice() {
      return window.innerWidth <= 768; // Adjust this value based on your needs
    }
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.setPlanDeeplinkPath(this.$router.currentRoute.fullPath);
    sessionStorage.setItem("setPlanDeeplinkPath", this.$router.currentRoute.fullPath);

    // Check if there's a voucher code in session storage and auto-verify it if exists
    const storedVoucherCode = sessionStorage.getItem("voucherCode");
    if (storedVoucherCode) {
      this.voucherCode = storedVoucherCode;
      this.verifyVoucher();
    }
    eventBus.$on("deeplink-voucher", (response) => {
      const storedVoucherCode = sessionStorage.getItem("voucherCode");
    if (storedVoucherCode) {
      this.voucherCode = storedVoucherCode;
      this.verifyVoucher();
    }
    })
    eventBus.$on("validate-coupon", (response) => {
      console.log("validate-coupon", response)
      // response.availabilityid
      // response.couponid
      // response.planid
      // response.planinterval
      // response.planname
      // response.nextbilling
      if(response.errorcode || response.reason){
        sessionStorage.removeItem("voucherCode");
        sessionStorage.removeItem("setPlanDeeplinkPath");
        this.setPlanDeeplinkPath(null);
        this.errorMsg = response.reason;
        eventBus.$emit("enableOverlayLoader", false);
        return;
      }
      if(response.finalamount === 0){
        let payload = {
              transactionpurpose: "SUBSCRIPTION",
              planid: response.planid,
              availabilityid: response.availabilityid,
              couponid: response.couponid,
            };
        eventBus.$emit("create-free-subscription", payload);
      }else {
        let payload = {
                transactionpurpose:"SUBSCRIPTION",
                planid: response.planid,
                couponid: response.couponid,
              };
        this.$router.push("/viewPlanAndCoupon/"+ payload.planid+"/"+payload.couponid);
      }
    })
    eventBus.$on("free-subscription-response", (response) => {
      console.log("response for free subscriptionvoucher", response);
      eventBus.$emit("enableOverlayLoader", false);
      if (!response.reason) {
        this.voucherSuccess = true;
        this.planName = response.planname;
        const nextBilling = response.nextbilling; // Assuming response.nextbilling = "2025-11-17"
        const formattedDate = nextBilling.split("-").reverse().join("/"); // Converts to "17/11/2025"
        this.planExpireDate = formattedDate;
       // this.planExpireDate = response.nextbilling;
        // this.paymentSuccessResponse = "You have successfully subscribed to plan";
        // this.transactionSuccess = true;
         this.setNewSubscription(true);
      } else {
        this.errorMsg = response.reason;
        eventBus.$emit("enableOverlayLoader", false);
        //this.voucherSuccess = true;
        //this.isPaymentSuccess = false;
       
      }
    });
  },
  mounted() {

  },

  methods: {
    ...mapActions(["getCouponDetails"]),
    ...mapMutations(["setPlanDeeplinkPath",  "setNewSubscription"]),
    verifyVoucher() {
      // Check if the user is logged in
      if (this.voucherCode) {
        this.setPlanDeeplinkPath(this.$router.currentRoute.fullPath);
        sessionStorage.setItem("setPlanDeeplinkPath", this.$router.currentRoute.fullPath)
        sessionStorage.setItem("voucherCode", this.voucherCode);
      }
      if (!this.subscriberid) {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload); // Trigger login popup
        return;
      }

      // If voucherCode is entered, store it in sessionStorage
      if (this.voucherCode) {
        sessionStorage.setItem("voucherCode", this.voucherCode);
        this.errorMsg = ""; // Clear any existing errors
        eventBus.$emit("enableOverlayLoader", true);
        // Call the API to verify the voucher code
        this.getCouponDetails(this.voucherCode)
          .then((response) => {
            console.log("response123",response.data.couponid)
            console.log("response1234",response.data.planid)
            // Handle successful API response
            if (response && response.data && !response.data.reason) {
              // this.voucherSuccess = true; // Mark as success
              // this.planName = response.data.planName; // Assume response contains planName
              // this.planExpireDate = response.data.planExpireDate; // Assume response contains planExpireDate
              let payload = {
                transactionpurpose:"SUBSCRIPTION",
                planid: response.data.planid,
                couponid: response.data.couponid,
              };

            //   if (payload.couponid && payload.planid) {
						// 	this.$router.push("/viewPlanAndCoupon/"+ payload.planid+"/"+payload.couponid);
						// }
            
              eventBus.$emit("plan-coupon-precheck", payload);

              // Clear session storage and state after success
              // sessionStorage.removeItem("voucherCode");
              // sessionStorage.removeItem("setPlanDeeplinkPath");
              // this.setPlanDeeplinkPath(null);
            } else {
              this.errorMsg = response.data.reason;
              eventBus.$emit("enableOverlayLoader", false);
              sessionStorage.removeItem("voucherCode");
             // sessionStorage.removeItem("setPlanDeeplinkPath");
              this.setPlanDeeplinkPath(null);
            }
          })
          .catch((error) => {
            // Handle API failure response
            this.errorMsg = error?.message || "Failed to verify voucher. Please try again.";
            eventBus.$emit("enableOverlayLoader", false);
            sessionStorage.removeItem("voucherCode");
              //sessionStorage.removeItem("setPlanDeeplinkPath");
              this.setPlanDeeplinkPath(null);
          });
      } else {
        // Handle case where voucherCode is empty
        this.errorMsg = "Please enter a voucher code.";
        eventBus.$emit("enableOverlayLoader", false);
      }
    },
    clearError() {
      // Clear error message when user starts typing
      this.errorMsg = "";
    },
    verifyCoupon(payload) {
      // Simulate the Vuex action method for verifying a coupon
      return new Promise((resolve, reject) => {
        if (!payload) {
          reject({ message: "Payload not found" });
          return;
        }
        let data = `?code=${payload.code}`; // Assuming makeQueryStringDync just appends a query string
        fetch(`https://vsms.mobiotics.com/testv1/subscriber/v1/coupon/<couponId>`, {
          method: "GET",
          headers: { "Content-Type": "application/json" }, // Assuming setHeader is for headers
        })
          .then((response) => {
            if (!response.ok) {
              return response.json().then((error) => Promise.reject(error));
            }
            return response.json();
          })
          .then((data) => resolve({ data }))
          .catch((error) => reject(error));
      });
    },
  },
};
</script>

<style scoped>
.voucher-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background: #191919;
 /* background-image: url("~@/assets/icons/mob_View_Background.png");*/
  /* background-image: url('~@/assets/icons/Oman_logo_ramadan_en.jpg'); Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.voucher-container {
  background-color: rgba(30, 30, 30, 0.9); /* Add transparency for better visibility */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #ffffff;
  max-width: 90%;
  width: 400px;
}
.voucher-logo {
  margin-bottom: 1rem;
}
.voucher-logo img {
  width: 100px;
  height: 100px;
}
.voucher-tittle{
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #FFFFFFB2;
}
.voucher-input-group {
  margin-bottom: 1.5rem;
}
.voucher-input-group label {
  display: flex;
  text-align: start;
  font-size: 16px;
  color: #8e8e93;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.voucher-input-group input {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #3a3a3c;
  border-radius: 5px;
  color: #ffffff;
}
.voucher-btn {
  border: 2px solid #E71A5A;
  background: #1d1d1d;
  padding: 0.625rem;
  width: 100%;
  border-radius: 0.5rem;
  color: #E71A5A;
  font-size: 1rem;
  font-weight: 600;
  line-height: 15px;
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
}
.voucher-btn:hover {
  background-color: #ff3b6b;
  color: #ffffff;
}
.voucher-error {
  color: #E72828; /* Red color for error messages */
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: left;
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
}
</style>
