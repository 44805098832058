<template>
  <div class="container">
    <div class="success-logo">
      <img src="@/assets/icons/success-logo.svg" alt="Success Logo" />
    </div>
    <h1 class="success-text">
      {{$t("Your voucher has been redeemed")}}
      <br />
      {{$t("successfully!")}}
    </h1>
    <p>Plan Name: {{ planName }}</p>
    <p>Plan Expire Date: {{ planExpireDate }}</p>
    <button @click="navigateHome" class="button">
      {{$t("Start Watching Now")}}
    </button>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapMutations } from "vuex";

export default {
  props: {
    planName: {
      type: String,
    },
    planExpireDate: {
      type: String,
    },
  },
  data() {
    return {
      autoRedirectTimer: null,
    };
  },
  mounted() {
    this.startAutoRedirect(); // Start the auto-redirect timer when the component is mounted
  },
  methods: {
    ...mapMutations(["setPlanDeeplinkPath"]),
    navigateHome() {
      this.clearAutoRedirect(); // Clear the timer
      eventBus.$emit("toggleSuccessMessagePopupClose", false);
      this.$router.push({ name: "Home"});
				eventBus.$emit("rerender-menus");
        sessionStorage.removeItem("voucherCode");
        sessionStorage.removeItem("setPlanDeeplinkPath");
        this.setPlanDeeplinkPath(null);
    },
    startAutoRedirect() {
      this.autoRedirectTimer = setTimeout(() => {
        this.navigateHome(); // Automatically redirect to home
      }, 5000); // 5 seconds
    },
    clearAutoRedirect() {
      if (this.autoRedirectTimer) {
        clearTimeout(this.autoRedirectTimer);
        this.autoRedirectTimer = null;
      }
    },
  },
  beforeDestroy() {
    this.clearAutoRedirect(); // Clear the timer when the component is destroyed
  },
};
</script>

<style scoped>
.container {
  background-color: #1E1E1E;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #ffffff;
  max-width: 90%;
  width: 400px;
}
.success-logo {
  margin-bottom: 20px;
}
.container h1 {
  /* font-size: 24px; */
  margin-bottom: 10px;
}
.success-text{
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1rem;
}
.container p {
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  font-size: 16px;
  margin: 5px 0;
  color: #656565;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
}
.container .button {
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  display: inline-block;
  background-color: #ff3366;
  color: white;
  font-weight: 600;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
  text-decoration: none;
}
</style>
